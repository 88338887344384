import LazyLoad from 'vanilla-lazyload';
document.addEventListener('DOMContentLoaded', () => {

  var lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy"
      // ... more custom settings?
  });
  lazyLoadInstance();

  let gallery = document.querySelector('.gallery');
  if(gallery) {
    new Viewer(gallery, {
      // url: 'data-original',
      movable: false,
      title: false,
      loop: true,
      zIndex: 9999,
      navbar: 0,
      loading: true,
      toolbar: {
        zoomIn: 0,
        zoomOut: 0,
        oneToOne: 0,
        reset: 0,
        prev: {
          show: 1,
          size: 'large',
        },
        play: 0,
        next: {
          show: 1,
          size: 'large',
        },
        rotateLeft: 0,
        rotateRight: 0,
        flipHorizontal: 0,
        flipVertical: 0,
      },
    });
  }

  /* PHONE VALIDATION */
  function phoneValidation (event) {
    let string = event.target.value;
    let value = string.slice(2).replace(/\D/g, '');
    event.target.value = '';
    let arr = value.split('');
    let rdyArr = ['+7'];
    let result = '';
    arr.forEach(function(digit, index) {
        if (index == 0) {
            rdyArr.push(' (');
        }
        if (index == 3) {
            rdyArr.push(') ');
        }
        if (index == 6 || index == 8) {
            rdyArr.push(' ');
        }
        if (index > 9) {
            return;
        }
        rdyArr.push(digit);
    })
    rdyArr.forEach(function (str) {
        result += str;
    })
    event.target.value = result;
  }

  const phoneInputs = document.querySelectorAll('input[type="tel"]');
  if(phoneInputs) {
    phoneInputs.forEach(phone => {
      phone.addEventListener('input', phoneValidation);
      phone.addEventListener('focus', phoneValidation);
      phone.addEventListener('change', phoneValidation);
    })
  }

})

$(function() {

  $('.nav-button').on('click', e => {
    $('.nav').toggleClass('active');
  })

  if($('.gallery-list').length > 5) {
    $('.gallery-slider').slick({
      slidesToShow: 5,
      prevArrow: $('.gallery .slick-prev'),
      nextArrow: $('.gallery .slick-next'),
      slidesToScroll: 5,
      mobileFirst: true,
      infinite: false,
      responsive: [
        {
          breakpoint: 1159,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 320,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ],
    })
  }

  $('body').on('keydown', function(e) {
    if(e.which == 27) {
      $('.modal').fadeOut(300);
      $('.modal-overlay').fadeOut(300);
    }
  })

  $('.menu-link').on('click', function(e) {
    e.preventDefault();
    $('.header').toggleClass('active');
  })

  $('.header-callback-link').on('click', function(e) {
    e.preventDefault();
    $('.modal-callback').fadeIn(300);
    $('.modal-overlay').fadeIn(300);
  })

  $('.callback-links .btn-product').on('click', function(e) {
    e.preventDefault();
    $('.modal-callback').fadeIn(300);
    $('.modal-overlay').fadeIn(300);
  });

  $('.product .btn-product').on('click', function(e) {
    e.preventDefault();
    let barrierType = $(this).parents('.product-item').data('type');
    $('.modal-smeta input[name="smeta"]').val(barrierType);
    $('.modal-smeta').fadeIn(300);
    $('.modal-overlay').fadeIn(300);
  })

  $('.header-link').on('click', function(e) {
    e.preventDefault();
    $('.modal-callback').fadeIn(300);
    $('.modal-overlay').fadeIn(300);
  });

  $('.modal-close').on('click', function(e) {
    $(this).parents('.modal').fadeOut(300);
    $('.modal-overlay').fadeOut(300);
  })
  $('.modal-overlay').on('click', function(e) {
    $('.modal').fadeOut(300);
    $('.modal-overlay').fadeOut(300);
  })

  $(document).on('wheel', function(e) {
    $('.modal').fadeOut(300);
    $('.modal-overlay').fadeOut(300);
  })

  $('input[type="tel"]').on('input focus change', function(e) {
    $(e.target).parent().removeClass('is-invalid');
  })

  $('.header-data .header-link').on('click', function(e) {
    e.preventDefault();
    $('.header-data').toggleClass('active');
  })

  $('.remote-item-link').on('click', function(e) {
    e.preventDefault();
    let remoteItem = $(this).parent();
    remoteItem.toggleClass('is-open');
    if(!remoteItem.hasClass('is-open')) {
      if (!remoteItem.hasClass('is-visited')) {
        remoteItem.addClass('is-visited');
      }
    }
  })
  $('.remote-item-close').on('click', function(e) {
    let remoteItem = $(this).parent().parent();
    remoteItem.removeClass('is-open');
    if (!remoteItem.hasClass('is-visited')) {
      remoteItem.addClass('is-visited');
    }
  })
  let now = new Date;
  $('.footer-year').text(now.getFullYear());
})
